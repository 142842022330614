import * as React from 'react';
import { HeaderLink } from '@smapiot/components';
import { routes } from '../routes';

export const SettingsLink: React.FC = () => {
  return (
    <HeaderLink exact to={routes.settings}>
      <i className="fas fa-cog" />
      <span className="hamburger-only"> Settings</span>
    </HeaderLink>
  );
};
