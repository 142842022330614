import * as React from 'react';
import { runPortal } from '@piral/portal-core';
import { Footer } from './components/Footer';
import { apiUrl, portalUrl } from './constants';

await runPortal({
  apiUrl,
  portalUrl,
  plans: ['free', 'premium'],
  providers: ['microsoft', 'github'],
  consent: true,
  components: {
    'layout-footer': Footer,
  },
  pages: {
    '/': React.lazy(() => import('./components/Teaser')),
    '/privacy': React.lazy(() => import('./components/Privacy')),
    '/terms': React.lazy(() => import('./components/Terms')),
    '/imprint': React.lazy(() => import('./components/Imprint')),
  },
});
