import * as React from 'react';
import { HeaderLink } from '@smapiot/components';
import { useGlobalState } from 'piral-core';
import { routes } from '../routes';

export const NotificationsLink: React.FC = () => {
  const notifications = useGlobalState((m) => m.notifications);
  const unread = notifications.filter((m) => m.options.unread);

  return (
    <HeaderLink exact to={routes.notifications}>
      <span className="notifications-link" data-active={unread.length > 0}>
        <i className="fas fa-bell" />
        <span className="notifications-link-badge">{unread.length}</span>
      </span>
      <span className="hamburger-only"> Notifications</span>
    </HeaderLink>
  );
};
