import { GlobalStateContext } from 'piral-core';
import type { RemoteData, CloudPortalState } from './types';
import type {
  FeedsDTO,
  ApiKeysDTO,
  RulesDTO,
  ConfigsDTO,
  RuleUpdateDetails,
  RuleCreateDetails,
  ApiKeyCreateDetails,
  ApiKeyUpdateDetails,
  FeedUpdateDetails,
  FeedCreateDetails,
  FullFeedDTO,
  ConfigUpdateDetails,
  ConfigCreateDetails,
  EntityCreateDetails,
  EntitiesDTO,
  EntityUpdateDetails,
  UserCreateDetails,
  UserUpdateDetails,
  UsersDTO,
  GroupsDTO,
  CurrentUserUpdateDetails,
  GroupCreateDetails,
  GroupUpdateDetails,
  CurrentUserDTO,
  UploadedPiletsDTO,
  AdminStatisticsDTO,
  NpmRegistryDTO,
  NpmPackageDTO,
  AllFeedConfigsDTO,
  GroupDetailsDTO,
  PiletUpdateDetails,
  FeaturesDTO,
  FeatureUpdateDetails,
  FeatureCreateDetails,
  PiletDTO,
} from '@smapiot/piral-cloud-browser';

function getTagOf(ctx: GlobalStateContext, feed: string, id: string) {
  const pilets = ctx.readState((m) => m.portal.selectedPilets[feed]);
  const tags = Object.keys(pilets || {});

  for (const tag of tags) {
    const data = pilets[tag].data;

    if (pilets[tag].loaded && Array.isArray(data?.items)) {
      if (data.items.some((m) => m.id === id)) {
        return tag;
      }
    }
  }

  return undefined;
}

export function setProgress(ctx: GlobalStateContext, id: string) {
  ctx.setPortalState((state) => ({
    ...state,
    progress: [...state.progress, id],
  }));
}

export function reloadFeeds(ctx: GlobalStateContext, loadingIds: Array<string> = []) {
  return ctx.http
    .doQueryFeeds()
    .catch((err) => {
      ctx.emit('api-error', err);
      return ctx.getPortalState((s) => s.feeds.data);
    })
    .then((data: FeedsDTO) => {
      ctx.setPortalState((state) => ({
        ...state,
        progress: state.progress.filter((m) => loadingIds.indexOf(m) === -1),
        feeds: {
          loaded: true,
          loading: false,
          data,
        },
      }));
    });
}

export function getPortalState<T>(ctx: GlobalStateContext, select: (state: CloudPortalState) => T): T {
  return ctx.readState((state) => select(state.portal));
}

export function setPortalState(ctx: GlobalStateContext, dispatch: (state: CloudPortalState) => CloudPortalState) {
  ctx.dispatch((state) => ({
    ...state,
    portal: dispatch(state.portal),
  }));
}

export function loadFeeds(ctx: GlobalStateContext) {
  ctx.setPortalState((state) => ({
    ...state,
    feeds: {
      loaded: false,
      loading: true,
      data: undefined,
    },
  }));

  return ctx.reloadFeeds();
}

export function loadFeedDetails(ctx: GlobalStateContext, feed: string) {
  return ctx.http
    .doQueryFeed(feed)
    .catch((err) => {
      ctx.emit('api-error', err);
      return ctx.getPortalState((s) => s.feeds.data.items.find((m) => m.id === feed));
    })
    .then((data: FullFeedDTO) => {
      ctx.setPortalState((state) => ({
        ...state,
        feeds: {
          ...state.feeds,
          data: {
            ...state.feeds.data,
            items: state.feeds.data.items.map((item) => (item.id === feed ? data : item)),
          },
        },
      }));
    });
}

export function reloadNpmPackageDetails(ctx: GlobalStateContext, feed: string, name: string) {
  return ctx.http
    .doQueryNpmPackage(feed, name)
    .catch((err) => {
      ctx.emit('api-error', err);
      return ctx.getPortalState((s) => s.npmPackages[feed]?.[name]?.data);
    })
    .then((data: NpmPackageDTO) => {
      ctx.setPortalState((state) => ({
        ...state,
        npmPackages: {
          ...state.npmPackages,
          [feed]: {
            ...state.npmPackages[feed],
            [name]: {
              loaded: true,
              loading: false,
              data,
            },
          },
        },
      }));
    });
}

export function loadNpmPackageDetails(ctx: GlobalStateContext, feed: string, name: string) {
  ctx.setPortalState((state) => ({
    ...state,
    npmPackages: {
      ...state.npmPackages,
      [feed]: {
        ...(state.npmPackages[feed] || {}),
        [name]: {
          loaded: false,
          loading: true,
          data: undefined,
        },
      },
    },
  }));

  return ctx.reloadNpmPackageDetails(feed, name);
}

export function reloadNpmRegistry(ctx: GlobalStateContext, feed: string) {
  return ctx.http
    .doQueryNpmRegistry(feed)
    .catch((err) => {
      ctx.emit('api-error', err);
      return ctx.getPortalState((s) => s.npmRegistries?.[feed]?.data);
    })
    .then((data: NpmRegistryDTO) => {
      ctx.setPortalState((state) => ({
        ...state,
        npmRegistries: {
          ...state.npmRegistries,
          [feed]: {
            loaded: true,
            loading: false,
            data,
          },
        },
      }));
    });
}

export function loadNpmRegistry(ctx: GlobalStateContext, feed: string) {
  ctx.setPortalState((state) => ({
    ...state,
    npmRegistries: {
      ...state.npmRegistries,
      [feed]: {
        loaded: false,
        loading: true,
        data: undefined,
      },
    },
  }));

  return ctx.reloadNpmRegistry(feed);
}

export function reloadApiKeys(ctx: GlobalStateContext, feed: string) {
  return ctx.http
    .doQueryApiKeys(feed)
    .catch((err) => {
      ctx.emit('api-error', err);
      return ctx.getPortalState((s) => s.apiKeys[feed]?.data);
    })
    .then((data: ApiKeysDTO) => {
      ctx.setPortalState((state) => ({
        ...state,
        apiKeys: {
          ...state.apiKeys,
          [feed]: {
            loaded: true,
            loading: false,
            data,
          },
        },
      }));
    });
}

export function loadApiKeys(ctx: GlobalStateContext, feed: string) {
  ctx.setPortalState((state) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      [feed]: {
        loaded: false,
        loading: true,
        data: undefined,
      },
    },
  }));

  return ctx.reloadApiKeys(feed);
}

export function loadUploadedPilets(ctx: GlobalStateContext, feed: string, name: string) {
  const item = getPortalState(ctx, (s) => s.uploadedPilets[feed]?.[name]);

  if (!item || !item.loading) {
    const { data, loaded } = item || {};
    const token = data?.continuation;
    const prevItems = data?.items || [];

    if (token !== '' || !loaded) {
      return ctx.http
        .doQueryUploadedPilets(feed, name, token)
        .catch((err) => {
          ctx.emit('api-error', err);
          const data = ctx.getPortalState((s) => s.uploadedPilets[feed]?.[name]?.data) as UploadedPiletsDTO;
          return {
            ...data,
            items: [],
          };
        })
        .then((data: UploadedPiletsDTO) => {
          ctx.setPortalState((state) => ({
            ...state,
            uploadedPilets: {
              ...state.uploadedPilets,
              [feed]: {
                ...state.uploadedPilets[feed],
                [name]: {
                  loading: false,
                  loaded: true,
                  data: {
                    ...data,
                    items: [...prevItems, ...data.items],
                  },
                },
              },
            },
          }));
        });
    }
  }

  return Promise.resolve();
}

export function clearUploadedPilets(ctx: GlobalStateContext, feed: string) {
  ctx.setPortalState((state) => ({
    ...state,
    uploadedPilets: {
      ...state.uploadedPilets,
      [feed]: undefined,
    },
  }));
}

export function reloadPilets(ctx: GlobalStateContext, feed: string, tag = 'latest', loadingIds: Array<string> = []) {
  return ctx.reloadSelectedPiletsByTag(feed, loadingIds);
}

export function loadPilets(ctx: GlobalStateContext, feed: string, tag = 'latest') {
  ctx.setPortalState((state) => ({
    ...state,
    uploadedPilets: {
      ...state.uploadedPilets,
      [feed]: {},
    },
    selectedPilets: {
      ...state.selectedPilets,
      [feed]: {
        ...state.selectedPilets[feed],
        [tag]: {
          loaded: false,
          loading: true,
          data: undefined,
        },
      },
    },
  }));

  return ctx.reloadPilets(feed, tag);
}

export async function reloadSelectedPiletsByTag(ctx: GlobalStateContext, feed: string, loadingIds: Array<string> = []) {
  try {
    const { tags } = await ctx.http.doQueryAllPilets(feed, undefined, 'selected');
    const apiResults = await Promise.all(tags.map((tag) => ctx.http.doQueryAllPilets(feed, tag, 'selected')));
    const data = Object.fromEntries(tags.map((tag, i) => [tag, apiResults[i].items]));
    ctx.setPortalState((state) => ({
      ...state,
      progress: state.progress.filter((m) => loadingIds.indexOf(m) === -1),
      selectedPilets: {
        ...state.selectedPilets,
        [feed]: {
          ...state.selectedPilets[feed],
          ...Object.fromEntries(tags.map((tag, i) => [tag, { loading: false, loaded: true, data: apiResults[i] }])),
        },
      },
      selectedPiletsByTag: {
        ...state.selectedPiletsByTag,
        [feed]: {
          data,
          loaded: true,
          loading: false,
        },
      },
    }));
    return data;
  } catch (err) {
    ctx.emit('api-error', err);
    return ctx.getPortalState((s) => s.selectedPiletsByTag[feed]?.data) as any;
  }
}

export function loadSelectedPiletsByTag(
  ctx: GlobalStateContext,
  feed: string,
): Promise<Record<string, Array<PiletDTO>>> {
  ctx.setPortalState((state) => ({
    ...state,
    selectedPiletsByTag: {
      ...state.selectedPiletsByTag,
      [feed]: {
        data: undefined,
        loaded: false,
        loading: true,
      },
    },
  }));

  return ctx.reloadSelectedPiletsByTag(feed);
}

export function reloadFeatures(ctx: GlobalStateContext, feed: string, loadingIds: Array<string> = []) {
  return ctx.http
    .doQueryFeatures(feed)
    .catch((err) => {
      ctx.emit('api-error', err);
      return ctx.getPortalState((s) => s.features?.[feed]?.data);
    })
    .then((data: FeaturesDTO) => {
      ctx.setPortalState((state) => ({
        ...state,
        progress: state.progress.filter((m) => loadingIds.indexOf(m) === -1),
        features: {
          ...state.features,
          [feed]: {
            loaded: true,
            loading: false,
            data,
          },
        },
      }));
    });
}

export function loadFeatures(ctx: GlobalStateContext, feed: string) {
  ctx.setPortalState((state) => ({
    ...state,
    features: {
      ...state.features,
      [feed]: {
        loaded: false,
        loading: true,
        data: undefined,
      },
    },
  }));

  return ctx.reloadFeatures(feed);
}

export function reloadRules(ctx: GlobalStateContext, feed: string, loadingIds: Array<string> = []) {
  return ctx.http
    .doQueryRules(feed)
    .catch((err) => {
      ctx.emit('api-error', err);
      return ctx.getPortalState((s) => s.rules?.[feed]?.data);
    })
    .then((data: RulesDTO) => {
      ctx.setPortalState((state) => ({
        ...state,
        progress: state.progress.filter((m) => loadingIds.indexOf(m) === -1),
        rules: {
          ...state.rules,
          [feed]: {
            loaded: true,
            loading: false,
            data,
          },
        },
      }));
    });
}

export function loadRules(ctx: GlobalStateContext, feed: string) {
  ctx.setPortalState((state) => ({
    ...state,
    rules: {
      ...state.rules,
      [feed]: {
        loaded: false,
        loading: true,
        data: undefined,
      },
    },
  }));

  return ctx.reloadRules(feed);
}

export function deleteFeed(ctx: GlobalStateContext, id: string) {
  return ctx.http.doDeleteFeed(id).then(() => ctx.loadFeeds());
}

export function createFeed(ctx: GlobalStateContext, data: FeedCreateDetails) {
  return ctx.http.doCreateFeed(data).then(() => ctx.loadFeeds());
}

export function toggleFeed(ctx: GlobalStateContext, id: string, active: boolean) {
  const data = { active };
  ctx.setProgress(id);
  return ctx.http.doUpdateFeed(id, data).then(() => ctx.reloadFeeds([id]));
}

export function updateFeed(ctx: GlobalStateContext, id: string, data: FeedUpdateDetails) {
  return ctx.http.doUpdateFeed(id, data).then(() => ctx.reloadFeeds([id]));
}

export function updateApiKey(ctx: GlobalStateContext, feed: string, id: string, data: ApiKeyUpdateDetails) {
  return ctx.http.doUpdateApiKey(feed, id, data).then(() => ctx.loadApiKeys(feed));
}

export function generateApiKey(ctx: GlobalStateContext, feed: string, data: ApiKeyCreateDetails) {
  return ctx.http.doGenerateApiKey(feed, data).then((res) => {
    ctx.loadApiKeys(feed);
    return res.apiKey;
  });
}

export function checkFeedExists(ctx: GlobalStateContext, feed: string) {
  return ctx.http.doQueryFeedExists(feed);
}

export function revokeKey(ctx: GlobalStateContext, feed: string, id: string) {
  return ctx.http.doRevokeApiKey(feed, id).then(() => ctx.loadApiKeys(feed));
}

export function shufflePilets(
  ctx: GlobalStateContext,
  feed: string,
  pilets: Array<{ id: string; priority: number }>,
  tag?: string,
) {
  const first = pilets[0];

  if (first) {
    const id = first.id;
    ctx.setProgress(id);
    tag ??= getTagOf(ctx, feed, id);
    return Promise.all(pilets.map((p) => ctx.http.doUpdatePilet(feed, p.id, { priority: p.priority }, tag))).then(() =>
      ctx.reloadPilets(feed, tag, [id]),
    );
  }

  return Promise.resolve();
}

export function updatePilet(
  ctx: GlobalStateContext,
  feed: string,
  id: string,
  details: PiletUpdateDetails,
  tag = getTagOf(ctx, feed, id),
) {
  ctx.setProgress(id);
  return ctx.http.doUpdatePilet(feed, id, details, tag).then(() => ctx.reloadPilets(feed, tag, [id]));
}

export function togglePilet(
  ctx: GlobalStateContext,
  feed: string,
  id: string,
  enabled: boolean,
  tag = getTagOf(ctx, feed, id),
) {
  ctx.setProgress(id);
  return ctx.http.doUpdatePilet(feed, id, { enabled }, tag).then(() => ctx.reloadPilets(feed, tag, [id]));
}

export function bundlePilet(
  ctx: GlobalStateContext,
  feed: string,
  id: string,
  bundled: boolean,
  tag = getTagOf(ctx, feed, id),
) {
  ctx.setProgress(id);
  return ctx.http.doUpdatePilet(feed, id, { bundled }, tag).then(() => ctx.reloadPilets(feed, tag, [id]));
}

export function selectPilet(ctx: GlobalStateContext, feed: string, id: string, tag = getTagOf(ctx, feed, id)) {
  ctx.setProgress(id);
  return ctx.http.doUpdatePilet(feed, id, { selected: true }, tag).then(() => ctx.reloadPilets(feed, tag, [id]));
}

export function removeDbPilet(
  ctx: GlobalStateContext,
  feed: string,
  id: string,
  all = false,
  tag = getTagOf(ctx, feed, id),
) {
  return ctx.http.doDeletePilet(feed, id, all).then(() => ctx.loadPilets(feed, tag));
}

export function removeRule(ctx: GlobalStateContext, feed: string, id: string) {
  return ctx.http.doDeleteRule(feed, id).then(() => ctx.loadRules(feed));
}

export function removeFeature(ctx: GlobalStateContext, feed: string, id: string) {
  return ctx.http.doDeleteFeature(feed, id).then(() => ctx.loadFeatures(feed));
}

export function shuffleRule(ctx: GlobalStateContext, feed: string, id: string, order: number) {
  const data = { order };
  setProgress(ctx, id);
  return ctx.http.doUpdateRule(feed, id, data).then(() => ctx.reloadRules(feed, [id]));
}

export function addFeature(ctx: GlobalStateContext, feed: string, data: FeatureCreateDetails) {
  return ctx.http.doAddFeature(feed, data).then(() => ctx.loadFeatures(feed));
}

export function addRule(ctx: GlobalStateContext, feed: string, data: RuleCreateDetails) {
  return ctx.http.doAddRule(feed, data).then(() => ctx.loadRules(feed));
}

export function updateRule(ctx: GlobalStateContext, feed: string, id: string, data: RuleUpdateDetails) {
  return ctx.http.doUpdateRule(feed, id, data).then(() => ctx.loadRules(feed));
}

export function updateFeature(ctx: GlobalStateContext, feed: string, id: string, data: FeatureUpdateDetails) {
  return ctx.http.doUpdateFeature(feed, id, data).then(() => ctx.loadFeatures(feed));
}

export function reloadConfigs(ctx: GlobalStateContext, feed: string, pilet: string) {
  return ctx.http
    .doQueryConfigs(feed, pilet)
    .catch((err) => {
      ctx.emit('api-error', err);
      return ctx.getPortalState((s) => s.piletConfigs[feed]?.[pilet]?.data);
    })
    .then((data: ConfigsDTO) => {
      ctx.setPortalState((state) => ({
        ...state,
        piletConfigs: {
          ...state.piletConfigs,
          [feed]: {
            ...state.piletConfigs[feed],
            [pilet]: {
              loaded: true,
              loading: false,
              data,
            },
          },
        },
      }));
    });
}

export function loadConfigs(ctx: GlobalStateContext, feed: string, pilet: string) {
  ctx.setPortalState((state) => ({
    ...state,
    piletConfigs: {
      ...state.piletConfigs,
      [feed]: {
        ...state.piletConfigs[feed],
        [pilet]: {
          loaded: false,
          loading: true,
          data: undefined,
        },
      },
    },
  }));

  return ctx.reloadConfigs(feed, pilet);
}

export function updateConfig(
  ctx: GlobalStateContext,
  feed: string,
  pilet: string,
  name: string,
  data: ConfigUpdateDetails,
) {
  return ctx.http.doUpdateConfig(feed, pilet, name, data).then(() => ctx.loadConfigs(feed, pilet));
}

export function removeConfig(ctx: GlobalStateContext, feed: string, pilet: string, config: string) {
  return ctx.http.doDeleteConfig(feed, pilet, config).then(() => ctx.loadConfigs(feed, pilet));
}

export function addConfig(ctx: GlobalStateContext, feed: string, pilet: string, data: ConfigCreateDetails) {
  return ctx.http.doAddConfig(feed, pilet, data).then(() => ctx.loadConfigs(feed, pilet));
}

export function reloadFeedConfigContainer(ctx: GlobalStateContext, feed: string, container: string) {
  return ctx.http
    .doQueryFeedConfigs(feed, container)
    .catch((err) => {
      ctx.emit('api-error', err);
      return ctx.getPortalState((s) => s.feedConfigs[feed]?.[container]?.data);
    })
    .then((data: ConfigsDTO) => {
      ctx.setPortalState((state) => ({
        ...state,
        feedConfigs: {
          ...state.feedConfigs,
          [feed]: {
            ...state.feedConfigs[feed],
            [container]: {
              loaded: true,
              loading: false,
              data,
            },
          },
        },
      }));
    });
}

export function loadFeedConfigs(ctx: GlobalStateContext, feed: string) {
  return ctx.http
    .doQueryAllFeedConfigs(feed)
    .then(
      (data: AllFeedConfigsDTO) => data.items,
      (err) => {
        ctx.emit('api-error', err);
        return [] as Array<string>;
      },
    )
    .then((items) => {
      ctx.setPortalState((state) => ({
        ...state,
        feedConfigs: {
          ...state.feedConfigs,
          [feed]: items.reduce<Record<string, RemoteData<ConfigsDTO>>>((p, c) => {
            p[c] = {
              loaded: false,
              loading: false,
              data: undefined,
            };
            return p;
          }, {}),
        },
      }));
    });
}

export function loadFeedConfigContainer(ctx: GlobalStateContext, feed: string, container: string) {
  ctx.setPortalState((state) => ({
    ...state,
    feedConfigs: {
      ...state.feedConfigs,
      [feed]: {
        ...state.feedConfigs[feed],
        [container]: {
          loaded: false,
          loading: true,
          data: undefined,
        },
      },
    },
  }));

  return ctx.reloadFeedConfigContainer(feed, container);
}

export function updateFeedConfig(
  ctx: GlobalStateContext,
  feed: string,
  container: string,
  name: string,
  data: ConfigUpdateDetails,
) {
  return ctx.http
    .doUpdateFeedConfig(feed, container, name, data)
    .then(() => ctx.loadFeedConfigContainer(feed, container));
}

export function removeFeedConfig(ctx: GlobalStateContext, feed: string, container: string, config: string) {
  return ctx.http.doDeleteFeedConfig(feed, container, config).then(() => ctx.loadFeedConfigContainer(feed, container));
}

export function addFeedConfig(ctx: GlobalStateContext, feed: string, container: string, data: ConfigCreateDetails) {
  return ctx.http.doAddFeedConfig(feed, container, data).then(() => ctx.loadFeedConfigContainer(feed, container));
}

export function reloadEntities(ctx: GlobalStateContext, feed: string) {
  return ctx.http
    .doQueryEntities(feed)
    .catch((err) => {
      ctx.emit('api-error', err);
      return ctx.getPortalState((s) => s.entities[feed]?.data);
    })
    .then((data: EntitiesDTO) => {
      ctx.setPortalState((state) => ({
        ...state,
        entities: {
          ...state.entities,
          [feed]: {
            loaded: true,
            loading: false,
            data,
          },
        },
      }));
    });
}

export function loadEntities(ctx: GlobalStateContext, feed: string) {
  ctx.setPortalState((state) => ({
    ...state,
    entities: {
      ...state.entities,
      [feed]: {
        loaded: false,
        loading: true,
        data: undefined,
      },
    },
  }));

  return ctx.reloadEntities(feed);
}

export function updateEntity(ctx: GlobalStateContext, feed: string, id: string, data: EntityUpdateDetails) {
  return ctx.http.doUpdateEntity(feed, id, data).then(() => ctx.loadEntities(feed));
}

export function removeEntity(ctx: GlobalStateContext, feed: string, id: string) {
  return ctx.http.doDeleteEntity(feed, id).then(() => ctx.loadEntities(feed));
}

export function addEntity(ctx: GlobalStateContext, feed: string, data: EntityCreateDetails) {
  return ctx.http.doAddEntity(feed, data).then(() => ctx.loadEntities(feed));
}

export function reloadUsers(ctx: GlobalStateContext) {
  return ctx.http
    .doQueryUsers()
    .catch((err) => {
      ctx.emit('api-error', err);
      ctx.getPortalState((state) => state.users.data);
    })
    .then((data: UsersDTO) => {
      ctx.setPortalState((state) => ({
        ...state,
        users: {
          loaded: true,
          loading: false,
          data,
        },
      }));
    });
}

export function loadUsers(ctx: GlobalStateContext) {
  ctx.setPortalState((state) => ({
    ...state,
    users: {
      loaded: false,
      loading: true,
      data: undefined,
    },
  }));

  return ctx.reloadUsers();
}

export function updateUser(ctx: GlobalStateContext, id: string, data: UserUpdateDetails) {
  ctx.setProgress(id);
  return ctx.http.doUpdateUser(id, data).then((res) => {
    ctx.loadUsers();
    return res.passphrase;
  });
}

export function removeUser(ctx: GlobalStateContext, id: string) {
  return ctx.http.doDeleteUser(id).then(() => ctx.loadUsers());
}

export function addUser(ctx: GlobalStateContext, data: UserCreateDetails) {
  return ctx.http.doAddUser(data).then((res) => {
    ctx.loadUsers();
    return res.passphrase;
  });
}

export function reloadCurrentUser(ctx: GlobalStateContext) {
  return ctx.http
    .doQueryCurrentUser()
    .catch((err) => {
      ctx.emit('api-error', err);
      return ctx.getPortalState((s) => s.currentUser.data);
    })
    .then((data: CurrentUserDTO) => {
      ctx.setPortalState((state) => ({
        ...state,
        currentUser: {
          data,
          loaded: true,
          loading: false,
        },
      }));
    });
}

export function loadCurrentUser(ctx: GlobalStateContext) {
  ctx.setPortalState((state) => ({
    ...state,
    currentUser: {
      data: undefined,
      loaded: false,
      loading: true,
    },
  }));

  return ctx.reloadCurrentUser();
}

export function reloadCurrentUserGroups(ctx: GlobalStateContext) {
  return ctx.http
    .doQueryGroups()
    .catch((err) => {
      ctx.emit('api-error', err);
      return ctx.getPortalState((s) => s.groups.data);
    })
    .then((data: GroupsDTO) => {
      ctx.setPortalState((state) => ({
        ...state,
        groups: {
          data,
          loaded: true,
          loading: false,
        },
      }));
    });
}

export function loadCurrentUserGroups(ctx: GlobalStateContext) {
  ctx.setPortalState((state) => ({
    ...state,
    groups: {
      data: undefined,
      loaded: false,
      loading: true,
    },
  }));

  return ctx.reloadCurrentUserGroups();
}

export function reloadUserGroupDetails(ctx: GlobalStateContext, id: string) {
  return ctx.http
    .doQueryGroup(id)
    .catch((err) => {
      ctx.emit('api-error', err);
      return ctx.getPortalState((s) => s.groupDetails[id]);
    })
    .then((data: GroupDetailsDTO) => {
      ctx.setPortalState((state) => ({
        ...state,
        groupDetails: {
          ...state.groupDetails,
          [id]: {
            data,
            loaded: true,
            loading: false,
          },
        },
      }));
    });
}

export function loadUserGroupDetails(ctx: GlobalStateContext, id: string) {
  ctx.setPortalState((state) => ({
    ...state,
    groupDetails: {
      ...state.groupDetails,
      [id]: {
        data: undefined,
        loaded: false,
        loading: true,
      },
    },
  }));

  return ctx.reloadUserGroupDetails(id);
}

export function updateGroup(ctx: GlobalStateContext, id: string, data: GroupUpdateDetails) {
  return ctx.http
    .doUpdateGroup(id, data)
    .then(() => Promise.all([ctx.reloadCurrentUserGroups(), ctx.reloadUserGroupDetails(id)]));
}

export function removeGroup(ctx: GlobalStateContext, id: string) {
  return ctx.http.doDeleteGroup(id).then(() => ctx.reloadCurrentUserGroups());
}

export function addGroup(ctx: GlobalStateContext, data: GroupCreateDetails) {
  return ctx.http.doAddGroup(data).then(() => ctx.reloadCurrentUserGroups());
}

export function updateCurrentUser(ctx: GlobalStateContext, data: CurrentUserUpdateDetails) {
  return ctx.http.doUpdateCurrentUser(data).then((res) => {
    ctx.loadCurrentUser();
    return res.passphrase;
  });
}

export function loadAdminStatistics(ctx: GlobalStateContext) {
  const item = getPortalState(ctx, (s) => s.feedStatistics);

  if (!item || !item.loading) {
    const { data, loaded } = item || {};
    const token = data?.continuation;
    const prevItems = data?.items || [];

    if (token !== '' || !loaded) {
      return ctx.http
        .doQueryAdminFeedStatistics(token)
        .catch((err) => {
          ctx.emit('api-error', err);
          return {
            ...data,
            items: [],
          };
        })
        .then((data: AdminStatisticsDTO) => {
          ctx.setPortalState((state) => ({
            ...state,
            feedStatistics: {
              loading: false,
              loaded: true,
              data: {
                ...data,
                items: [...prevItems, ...data.items],
              },
            },
          }));
        });
    }
  }

  return Promise.resolve();
}
