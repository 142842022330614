import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useGlobalState } from 'piral-core';

interface RouterProps {
  children?: React.ReactNode;
}

export const Router: React.FC<RouterProps> = ({ children }) => {
  const basePath = useGlobalState((s) => s.portal.basePath);
  return <BrowserRouter basename={basePath}>{children}</BrowserRouter>;
};
