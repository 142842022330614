import { useCallback, useEffect, useRef, useState } from 'react';

export function useSearch<T>(
  search: (value: string, continuation: string, signal: AbortSignal) => Promise<[Array<T>, string]>,
) {
  interface SearchState {
    value: string;
    loading: boolean;
    ready: boolean;
    offset: string;
    next: boolean;
    done: boolean;
    items: Array<T>;
  }

  const ac = useRef<AbortController>();

  if (!ac.current) {
    ac.current = new AbortController();
  }

  const [state, setState] = useState<SearchState>({
    value: '',
    offset: '',
    loading: false,
    ready: false,
    next: true,
    items: [],
    done: false,
  });
  const shouldLoad = state.next && !state.loading && !state.done;
  const loadMore = useCallback(
    () =>
      setState((state) => ({
        ...state,
        next: true,
      })),
    [],
  );

  const setValue = useCallback((value: string) => {
    setState({
      value,
      offset: '',
      loading: false,
      ready: false,
      next: true,
      items: [],
      done: false,
    });
  }, []);

  useEffect(() => {
    return () => {
      ac.current.abort();
    };
  }, []);

  useEffect(() => {
    if (shouldLoad) {
      search(state.value, state.offset, ac.current.signal).then(([items, continuation]) => {
        if (!ac.current.signal.aborted) {
          setState((state) => ({
            ...state,
            next: false,
            done: !continuation,
            loading: false,
            ready: true,
            offset: continuation,
            items: [...state.items, ...items],
          }));
        }
      });

      setState({
        ...state,
        loading: true,
      });
    }
  }, [shouldLoad]);

  return [setValue, state.ready, state.items, loadMore, state.loading, state.offset] as const;
}
