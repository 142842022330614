import * as React from 'react';
import { SearchAsync } from './SearchAsync';
import { LoadMore } from './LoadMore';
import { usePiletApi } from '../hooks/usePiletApi';
import { ApiPaginatedData } from '../types';

function toEndpointPath(path: string, params: Record<string, string>) {
  const sym = path.indexOf('?') !== -1 ? '&' : '?';
  const query = Object.entries(params)
    .filter(([key, value]) => key && typeof value === 'string')
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  return `${path}${sym}${query}`;
}

function defaultQuery(q: string, offset: string | undefined): Record<string, string> {
  return {
    q,
    offset,
  };
}

export interface FuzzySearchProps<T> {
  path: string;
  children(items: Array<T>, res: any): React.ReactNode;
  toQuery?(q: string, offset: string | undefined): Record<string, string>;
}

export function FuzzySearch<T>({ path, children, toQuery = defaultQuery }: FuzzySearchProps<T>) {
  const api = usePiletApi();
  const response = React.useRef({} as ApiPaginatedData<T>);

  const performSearch = React.useCallback(
    async (query: string, offset: string, signal: AbortSignal): Promise<[Array<T>, string]> => {
      const params = toQuery(query, offset);
      const ep = toEndpointPath(path, params);

      try {
        const { items, continuation } = (response.current = await api.http.doGet<ApiPaginatedData<T>>(ep, { signal }));
        return [items, continuation];
      } catch {
        return [[], ''];
      }
    },
    [path],
  );

  return (
    <SearchAsync<T> onSearch={performSearch}>
      {(items, next, loading, hasOffset) => (
        <>
          {children(items, response.current)}
          <LoadMore onNext={next} loading={loading} canLoad={!!hasOffset} />
        </>
      )}
    </SearchAsync>
  );
}
