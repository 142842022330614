import * as React from 'react';
import {
  Spinner,
  BreadcrumbItem,
  BreadcrumbsContainer,
  ModalsDialog,
  DashboardTile,
  NotFound,
} from '@smapiot/components';
import { createInstance } from 'piral-core';
import { createBreadcrumbsApi } from 'piral-breadcrumbs';
import { createDashboardApi, Dashboard } from 'piral-dashboard';
import { createModalsApi } from 'piral-modals';
import { createNotificationsApi } from 'piral-notifications';
import { createCloudPortalApi } from './plugins';
import { http } from './http';
import { DisplayErrorExtension, SearchExtension } from './extensions';
import { DashboardContainer, ModalsHost, Router } from './components';
import { Layout } from './layout/UserLayout';
import { routes, breadcrumbs } from './routes';
import type { GeneralData } from './types';

export function setupInstance(pages: Record<string, React.FC>, data: GeneralData) {
  const Status = React.lazy(() => import('./pages/Status'));
  const PageError = React.lazy(() => import('./pages/PageError'));
  const Notifications = React.lazy(() => import('./pages/Notifications'));
  const Settings = React.lazy(() => import('./pages/Settings'));
  const NoAccess = React.lazy(() => import('./pages/NoAccess'));

  const instance = createInstance({
    plugins: [
      createBreadcrumbsApi({ breadcrumbs }),
      createDashboardApi(),
      createModalsApi(),
      createNotificationsApi({
        defaultOptions: {
          autoClose: 0,
          type: 'info',
          unread: true,
        },
      }),
      createCloudPortalApi(data),
    ],
    state: {
      components: {
        Router,
        LoadingIndicator: Spinner,
        BreadcrumbItem: BreadcrumbItem as any,
        BreadcrumbsContainer,
        Layout,
        ModalsHost,
        ModalsDialog: ModalsDialog as any,
        DashboardContainer,
        DashboardTile: DashboardTile as any,
      },
      errorComponents: {
        not_found: NotFound as any,
        page: PageError,
      },
      routes: {
        ...pages,
        [routes.home]: Dashboard,
        [routes.noauth]: NoAccess,
        [routes.notifications]: Notifications,
        [routes.settings]: Settings,
        [routes.status]: Status,
      },
    },
    requestPilets() {
      return http.getPilets();
    },
  });

  instance.root.on('api-error', () =>
    instance.root.showNotification('The HTTP request failed.', {
      title: 'API Error',
      type: 'error',
    }),
  );

  instance.root.registerExtension('display-error', DisplayErrorExtension);
  instance.root.registerExtension('search', SearchExtension);

  return instance;
}
