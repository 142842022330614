import * as React from 'react';
import { AccountInfo } from '@smapiot/components';
import { ExtensionSlot, useGlobalState } from 'piral-core';
import { Breadcrumbs } from 'piral-breadcrumbs';
import { Modals } from 'piral-modals';
import { Header } from './Header';
import { Content } from './Content';
import { HeaderLink, HeaderLinks, NotificationsLink, SettingsLink } from '../components';
import { logout } from '../http';
import { routes } from '../routes';

function doLogout(ev: React.SyntheticEvent) {
  ev.preventDefault();
  logout();
  return false;
}

const DefaultHeader = () => {
  return (
    <Header>
      <HeaderLinks />
      <NotificationsLink />
      <SettingsLink />
      <HeaderLink exact to={routes.logout} onClick={doLogout}>
        <i className="fas fa-sign-out-alt" />
        <span className="hamburger-only"> Logout</span>
      </HeaderLink>
    </Header>
  );
};

const Trackers: React.FC = () => {
  const trackers = useGlobalState((m) => m.registry.trackers);

  return (
    <>
      {Object.keys(trackers).map((m) => {
        const Tracker = trackers[m];
        return <Tracker key={m} />;
      })}
    </>
  );
};

const Account: React.FC = () => {
  const user = useGlobalState((m) => m.portal.currentUser?.data);
  const userName = user?.name;
  const userId = user?.id;
  const name = React.useRef(userId);

  if (typeof userName === 'string') {
    name.current = userName || userId;
  }

  return <AccountInfo userName={name.current} title={userId} />;
};

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  React.useEffect(() => {
    document.body.className = 'logged-in';
  }, []);

  return (
    <>
      <ExtensionSlot name="layout-header" params={{ mode: 'user' }} empty={DefaultHeader} />
      <div className="container topbar">
        <Breadcrumbs />
        <Account />
      </div>
      <Content>{children}</Content>
      <Trackers />
      <Modals />
      <ExtensionSlot name="layout-footer" params={{ mode: 'user' }} />
    </>
  );
};
