import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { canRunPortal, configureClient } from './http';
import { setupInstance } from './instance';

export * from './plugins/types';
export * from './types';

export interface PortalOptions {
  apiUrl: string;
  portalUrl: string;
  plans: Array<string>;
  consent: boolean;
  pages: Record<string, React.FC>;
  providers: Array<string>;
  components: Record<string, React.FC>;
}

function getPath(url: string) {
  const el = document.createElement('a');
  el.href = url;
  return el.pathname;
}

function normalize(path: string) {
  const fragmentPos = path.indexOf('#');

  if (fragmentPos !== -1) {
    path = path.substring(0, fragmentPos);
  }

  const queryPos = path.indexOf('?');

  if (queryPos !== -1) {
    path = path.substring(0, queryPos);
  }

  if (path.endsWith('/')) {
    path = path.substring(0, path.length - 1);
  }

  return path;
}

function loadSdk(url: string) {
  return new Promise<void>((resolve, reject) => {
    const script = document.body.appendChild(document.createElement('script'));
    script.src = `${url}/api-docs/sdk.js`;
    script.onload = () => resolve();
    script.onerror = (event, _source, _lineno, _colno, error) => reject(error || event);
  });
}

function loadCurrentUser(url: string) {
  return fetch(`${url}/api/v1/current-user`, { credentials: 'include' }).then((res) => {
    if (res.status === 200) {
      return res.json();
    } else {
      return undefined;
    }
  });
}

export async function runPortal(options: PortalOptions) {
  if (canRunPortal()) {
    const [user] = await Promise.all([loadCurrentUser(options.apiUrl), loadSdk(options.apiUrl)]);
  
    configureClient(options.apiUrl);
  
    const instance = setupInstance(options.pages, {
      currentUser: user,
      providers: options.providers,
      plans: options.plans,
      consent: options.consent,
      apiUrl: options.apiUrl,
      basePath: normalize(getPath(options.portalUrl)),
    });
  
    Object.entries(options.components).forEach(([name, component]) => {
      instance.root.registerExtension(name, component);
    });
  
    const root = createRoot(document.querySelector('#app'));
    root.render(<App pages={options.pages} instance={instance} user={user} />);
  
    return instance;
  }
}
