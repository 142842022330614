import * as React from 'react';
import { Link } from 'react-router-dom';
import { PageFooter, FooterMenu, FooterLink } from '@smapiot/components';
import { copyright, email, fax, legal, phone } from '../data';

export const Footer: React.FC = () => (
  <PageFooter
    copyrightHtml={
      <>
        {copyright}
        <span className="footer-status">
          <i className="fas fa-heartbeat" />
          <Link to="/status">Status</Link>
        </span>
      </>
    }
    left={
      <FooterMenu title="Contact">
        <FooterLink url="mailto:info@smapiot.com">
          <i className="far fa-envelope" /> {email}
        </FooterLink>
        <FooterLink url={`tel:${phone}`}>
          <i className="fas fa-phone" /> {phone}
        </FooterLink>
        <FooterLink url={`fax:${fax}`}>
          <i className="fas fa-fax" /> {fax}
        </FooterLink>
      </FooterMenu>
    }
    right={
      <FooterMenu title="Legal">
        {legal.map((l) => (
          <FooterLink key={l.link} url={l.link}>
            {l.name}
          </FooterLink>
        ))}
      </FooterMenu>
    }
  ></PageFooter>
);
