import { useRef } from 'react';

export function useShuffle(canInitiallyShuffle = false) {
  const container = useRef<HTMLDivElement>(undefined);
  const canShuffle = useRef(canInitiallyShuffle);
  const startShuffle = () => {
    canShuffle.current = true;
  };

  const endShuffle = () => {
    canShuffle.current = false;
  };

  return [container, canShuffle, startShuffle, endShuffle] as const;
}
