import * as React from 'react';
import { SearchBox } from '@smapiot/components';

export interface SearchProps<T> {
  items: Array<T>;
  children(items: Array<T>): React.ReactNode;
  filter(item: T, value: string): boolean;
}

export function Search<T>({ filter, children, items }: SearchProps<T>) {
  const [value, setValue] = React.useState('');
  const renderItems = value ? items.filter((item) => filter(item, value)) : items;

  return (
    <>
      <div className="search-filter">
        <SearchBox input={value} onSearch={setValue} />
      </div>
      {children(renderItems)}
    </>
  );
}
