export const routeFor = {
  home: () => `/`,
  logout: () => '/auth/logout',
  privacy: () => `/privacy`,
  terms: () => `/terms`,
  imprint: () => `/imprint`,
  status: () => `/status`,
  noauth: () => `/noauth`,
  notifications: () => `/notifications`,
  settings: () => `/settings`,
};

type RouteKeys = keyof typeof routeFor;

export type Routes = {
  [P in RouteKeys]: string;
};

export const routes: Routes = Object.keys(routeFor).reduce((prev, curr: RouteKeys) => {
  prev[curr] = routeFor[curr]();
  return prev;
}, {} as Routes);

export const breadcrumbs = [
  {
    path: routes.home,
    parent: undefined,
    title: 'Home',
  },
  {
    path: routes.notifications,
    parent: routes.home,
    title: 'Notifications',
  },
  {
    path: routes.settings,
    parent: routes.home,
    title: 'Settings',
  },
  {
    path: routes.privacy,
    parent: routes.home,
    title: 'Data privacy',
  },
  {
    path: routes.terms,
    parent: routes.home,
    title: 'Terms and conditions',
  },
  {
    path: routes.imprint,
    parent: routes.home,
    title: 'Imprint',
  },
  {
    path: routes.status,
    parent: routes.home,
    title: 'Status',
  },
];
