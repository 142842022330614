import { http } from './http';

export function connectEvents() {
  const wsUrl = http.getEvents();
  return new WebSocket(wsUrl);
}

export const handlers: Record<string, Array<(data: any) => void>> = {};

export function dispatchEvent(type: string, data: any) {
  const array = handlers[type] || [];
  array.forEach((handler) => handler(data));
}

export function addHandler<T = any>(type: string, handler: (data: T) => void) {
  const array = handlers[type] || (handlers[type] = []);
  const index = array.indexOf(handler);

  if (index === -1) {
    array.push(handler);
  }
}

export function removeHandler<T = any>(type: string, handler: (data: T) => void) {
  const array = handlers[type] || (handlers[type] = []);
  const index = array.indexOf(handler);

  if (index !== -1) {
    array.splice(index, 1);
  }
}
