import { useState, useEffect, useRef } from 'react';

export function useAfterVisible(time: number, cb: () => void) {
  const ref = useRef<HTMLDivElement>(null);
  const [intersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((ev) => {
      setIntersecting(ev.some((m) => m.isIntersecting));
    });
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref.current]);

  useEffect(() => {
    if (intersecting) {
      const tid = setTimeout(cb, time);
      return () => clearTimeout(tid);
    }
  }, [intersecting, cb]);

  return ref;
}
