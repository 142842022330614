import * as React from 'react';
import { usePiletApi } from './usePiletApi';

const memberNameCache: Record<string, string> = {};

export function setMemberName(id: string, name: string) {
  memberNameCache[id] = name;
}

export function getMemberName(id: string) {
  return memberNameCache[id] || id;
}

export function useDisplayMember() {
  const api = usePiletApi();
  return (id: string) => <api.Extension name="display-member" params={{ id }} empty={() => getMemberName(id)} />;
}

export function useSuggestMember(members: Array<string>) {
  const api = usePiletApi();
  const debounce = React.useRef(0);

  return React.useCallback(
    (input: string) => {
      window.clearTimeout(debounce.current);

      return new Promise<Array<{ display: string; value: string }>>((resolve) => {
        debounce.current = window.setTimeout(() => {
          resolve(
            api.http.doQueryAllUsers(input).then(({ items }) =>
              items
                .filter((member) => !members.includes(member.id))
                .map((member) => {
                  setMemberName(member.id, member.name);
                  return { value: member.id, display: getMemberName(member.id) };
                }),
            ),
          );
        }, 100);
      });
    },
    [members],
  );
}
