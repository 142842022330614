import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useTagQueryParam() {
  const history = useHistory();
  const search = useLocation().search;
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const currentTag = queryParams.get('tag') || 'latest';

  const setTag = useCallback(
    (newTag: string) => {
      if (newTag === 'latest') {
        queryParams.delete('tag');
      } else {
        queryParams.set('tag', newTag);
      }
      history.push({ search: queryParams.toString() });
    },
    [queryParams],
  );

  return [currentTag, setTag] as [string, (newValue: string) => void];
}
