import * as React from 'react';
import { useGlobalState } from 'piral-core';
import { CookieConsent, Boundary, StandardError, Spinner } from '@smapiot/components';

export interface ContentProps {
  children: React.ReactNode;
}

export const Content: React.FC<ContentProps> = ({ children }) => {
  const consent = useGlobalState((s) => s.portal.consent);

  return (
    <div className="content">
      <Boundary ShowError={StandardError as any}>
        <React.Suspense fallback={<Spinner />}>{children}</React.Suspense>
      </Boundary>
      {consent && <CookieConsent />}
    </div>
  );
};
