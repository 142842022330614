import type { EventDefinitions, FeedServiceApiClient } from '@smapiot/piral-cloud-browser';

const reAuthWindowName = 'piral-feed-service-re-login';
const reAuthPath = '/re-auth';
const reAuthContent = 'authorized';

export let http: FeedServiceApiClient;

export function configureClient(hostUrl: string) {
  // @ts-ignore
  http = feedService.createServiceClient({
    resolveApiKey() {
      return Promise.resolve(undefined);
    },
    apiKeyType: 'none',
    host: hostUrl,
    defaults: {
      credentials: 'include',
    },
  });
}

export function attachUnauthorizedHandler(cb: () => Promise<void>) {
  if ('on' in http) {
    const handler = (ev: EventDefinitions['unauthorized']) => {
      const delay = cb();
      ev.retry(delay);
    };
    http.on('unauthorized', handler);
    return () => {
      http.off('unauthorized', handler);
    };
  }
}

export function isAuthorized(ev: MessageEvent) {
  return ev.data === reAuthContent;
}

export function canRunPortal() {
  if (location.href.endsWith(reAuthPath) && window.name === reAuthWindowName) {
    window.opener?.postMessage(reAuthContent);
    close();
    return false;
  }

  return true;
}

export function login() {
  // @ts-ignore
  location.href = `${http.host}/auth/login?to=${encodeURIComponent(location.href)}`;
}

export function relogin() {
  const url = `${http.host}/auth/login?to=${encodeURIComponent(location.origin + reAuthPath)}`;
  window.open(url, reAuthWindowName, 'height=800,width=400');
}

export function logout() {
  // @ts-ignore
  location.href = `${http.host}/auth/logout?to=${encodeURIComponent(location.href)}`;
}
