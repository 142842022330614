const companyName = 'smapiot GmbH';
const year = new Date().getFullYear().toString();

export const copyright = `© ${year} ${companyName}. All rights reserved.`;

export const email = 'info@smapiot.com';

export const phone = '+49 (0) 8102 9998476';

export const fax = '+49 (0) 8102 9984395';

export const legal = [
  {
    name: 'Imprint',
    link: '/imprint',
  },
  {
    name: 'Data Privacy',
    link: '/privacy',
  },
  {
    name: 'Terms and Conditions',
    link: '/terms',
  },
];
