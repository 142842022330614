import * as React from 'react';
import { SearchBox, Spinner } from '@smapiot/components';
import { useDebounce } from '../hooks/useDebounce';
import { useSearch } from '../hooks/useSearch';

export interface SearchAsyncProps<T> {
  children(items: Array<T>, next: () => void, loading: boolean, offset: string): React.ReactNode;
  onSearch(value: string, offset: string, signal: AbortSignal): Promise<[Array<T>, string]>;
}

export function SearchAsync<T>({ onSearch, children }: SearchAsyncProps<T>) {
  const [search, setSearch] = React.useState('');
  const current = useDebounce(search, 600);
  const [trigger, ready, items, next, loading, offset] = useSearch(onSearch);

  React.useEffect(() => trigger(current), [current]);

  return (
    <>
      <div className="search-filter">
        <SearchBox input={search} onSearch={setSearch} />
      </div>
      {!ready ? <Spinner /> : children(items, next, loading, offset)}
    </>
  );
}
