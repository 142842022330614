import * as React from 'react';
import { DropdownField } from '@smapiot/components';
import { useTagQueryParam } from '../hooks/useTagQueryParam';

export interface TagSelectorProps {
  tags: Array<string>;
}

export const TagSelector: React.FC<TagSelectorProps> = ({ tags }) => {
  const [tag, setTag] = useTagQueryParam();

  return (
    <>
      {Array.isArray(tags) && tags.length > 1 && (
        <div className="pilet-table-tags-dropdown">
          <DropdownField label="tags" onChange={setTag} value={tag}>
            {tags.map((tag) => (
              <option key={tag}>{tag}</option>
            ))}
          </DropdownField>
        </div>
      )}
    </>
  );
};
