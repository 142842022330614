import { useEffect } from 'react';
import type { PiletApi, CurrentUserDTO } from '@piral/portal';

export function useCurrentUser(api: PiletApi): [boolean, CurrentUserDTO] {
  const loadCurrentUser = api.usePortalAction('loadCurrentUser');
  const state = api.usePortalState((m) => m.currentUser) || {
    data: undefined,
    loaded: false,
    loading: false,
  };

  useEffect(() => {
    if (!state.loaded && !state.loading) {
      loadCurrentUser();
    }

    return () => {};
  }, []);

  return [!state.loaded, state.data];
}
