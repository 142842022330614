import * as React from 'react';
import { Spinner } from '@smapiot/components';

export interface LoadMoreProps {
  onNext: () => void;
  loading?: boolean;
  canLoad?: boolean;
}

export const LoadMore: React.FC<LoadMoreProps> = ({ onNext, loading, canLoad }) => {
  const ref = React.useRef<HTMLDivElement>();
  const waiting = !loading && canLoad;

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting && waiting) {
        onNext();
      }
    });
    const container = ref.current;
    observer.observe(container);
    return () => observer.unobserve(container);
  }, [onNext, waiting]);

  return <div ref={ref}>{(loading || canLoad) && <Spinner />}</div>;
};
