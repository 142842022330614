import * as React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useGlobalState } from 'piral-core';
import type { HeaderActionSettings } from '../plugins';

function linkSorter(a: [string, HeaderActionSettings], b: [string, HeaderActionSettings]): number {
  const aOrder = a[1].order || 0;
  const bOrder = b[1].order || 0;
  return aOrder - bOrder;
}

export const HeaderLink: React.FC<NavLinkProps> = (props) => (
  <li className="h2">
    <NavLink {...props} className="nav-link" />
  </li>
);

export const HeaderLinks: React.FC = () => {
  const links = useGlobalState((s) => s.registry.headerLinks);
  const children = Object.entries(links)
    .sort(linkSorter)
    .map(([key, item]) => (
      <HeaderLink exact to={item.path} key={key}>
        {item.title}
      </HeaderLink>
    ));
  return <>{children}</>;
};
