import * as React from 'react';
import { useGlobalState } from 'piral-core';
import { PageHeader, HeaderMenu } from '@smapiot/components';
import { routes } from '../routes';

interface HeaderProps {
  children: React.ReactNode;
}

export const Header: React.FC<HeaderProps> = ({ children }) => {
  const basePath = useGlobalState((s) => s.portal.basePath);

  return (
    <PageHeader home={routes.home} logo={<img src={`${basePath}/logo.png`} alt="Piral Cloud" />}>
      <HeaderMenu>{children}</HeaderMenu>
    </PageHeader>
  );
};
