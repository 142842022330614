import { useEffect, useMemo } from 'react';
import type { CloudPortalState } from '@piral/portal';
import { usePiletApi } from './usePiletApi';
import type { ApiData, RemoteData, RemoteItem } from '../types';

export function useRemoteValue<U, T extends RemoteItem<U>>(
  select: (state: CloudPortalState) => T,
  load: () => void,
  reloadDependencies: Array<any> = [],
): [boolean, U] {
  const api = usePiletApi();
  const data = api.usePortalState(select) || {
    data: undefined as U,
    loaded: false,
    loading: false,
  };
  const shouldLoad = !data.loaded && !data.loading;

  useEffect(() => {
    if (shouldLoad) {
      load();
    }

    return () => {};
  }, [...reloadDependencies, shouldLoad]);

  return [!data.loaded, data.data];
}

export function useRemoteItem<U>([loading, data]: [boolean, ApiData<U>], select: (item: U) => boolean): [boolean, U] {
  const [item] = ((data && data.items) || []).filter(select);
  return [loading, item];
}

export function useRemoteData<U extends ApiData<any>, T extends RemoteData<U>>(
  select: (state: CloudPortalState) => T,
  load: () => void,
  reloadDependencies: Array<any> = [],
): [boolean, U] {
  return useRemoteValue(select, load, reloadDependencies);
}

export function useProgress(id: string) {
  const api = usePiletApi();
  return api.usePortalState((m) => m.progress.includes(id));
}

export function usePiletName(pilet: string) {
  return useMemo(() => {
    try {
      const nameVersion = atob(pilet);
      const index = nameVersion.lastIndexOf('@');
      return nameVersion.substr(0, index);
    } catch (error) {
      return 'unknown pilet';
    }
  }, [pilet]);
}
