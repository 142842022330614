import * as React from 'react';
import type { BaseComponentProps, CurrentUserDTO } from '@piral/portal';
import { useCurrentUser } from '../hooks/useCurrentUser';

export function withUser<T extends BaseComponentProps>(
  Component: React.FC<T>,
  check: (user: CurrentUserDTO) => boolean = () => true,
): React.FC<T> {
  return (props) => {
    const [loading, data] = useCurrentUser(props.piral);

    if (!loading && check(data)) {
      return <Component {...props} />;
    }

    return null;
  };
}

export function withAdminUser<T extends BaseComponentProps>(Component: React.FC<T>) {
  return withUser(Component, (user) => user.role === 'admin');
}

export function withNonGuestUser<T extends BaseComponentProps>(Component: React.FC<T>) {
  return withUser(Component, (user) => user.role !== 'guest');
}

export function withPremiumUser<T extends BaseComponentProps>(Component: React.FC<T>) {
  return withUser(Component, (user) => user.plan === 'premium');
}
