import * as React from 'react';
import { useModal } from '@smapiot/components';
import { ModalsHostProps } from 'piral-modals';

export const ModalsHost: React.FC<ModalsHostProps> = ({ open, close, children }) => {
  useModal(open);

  const nodes = React.Children.toArray(children);
  const blocked = nodes.some((child) => {
    return React.isValidElement(child) && child.props.options?.block;
  });

  const onClose = () => {
    if (!blocked) {
      close();
    }
  };

  const keydown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 27 || e.code === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      onClose();
    }
  };

  React.useLayoutEffect(() => {
    if (nodes.length > 0) {
      const dialog = document.querySelector<HTMLElement>('.modal .modal-dialog');

      if (dialog) {
        dialog.setAttribute('tabindex', '0');
        setTimeout(() => dialog.focus(), 0);
      }
    }
  }, [nodes.length]);

  return (
    <div className="modal" onClick={onClose} onKeyDown={keydown} tabIndex={0}>
      {children}
    </div>
  );
};
