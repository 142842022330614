import * as React from 'react';
import type { BaseComponentProps } from '@piral/portal';
import { PiletApiProvider } from './Provider';

export function withProvider<T>(Component: React.FC<T>): React.FC<T & BaseComponentProps> {
  return (props) => (
    <PiletApiProvider.Provider value={props.piral}>
      <Component {...props} />
    </PiletApiProvider.Provider>
  );
}
