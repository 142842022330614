import * as React from 'react';
import { PiralInstance } from 'piral-core';
import { Spinner } from '@smapiot/components';

const UserApp = React.lazy(() => import('./UserApp'));
const AnonymousApp = React.lazy(() => import('./AnonymousApp'));

export interface AppProps {
  instance: PiralInstance;
  pages: Record<string, React.FC>;
  user: any;
}

export const App: React.FC<AppProps> = ({ instance, pages, user }) => (
  <React.Suspense fallback={<Spinner />}>
    {user ? <UserApp instance={instance} /> : <AnonymousApp instance={instance} pages={pages} />}
  </React.Suspense>
);
